export const mobilePhotos = [
  {
    src: require("../../assets/portfolioImages/Portfolio (1).webp"),
    width: 4,
    height: 6,
    title: "Alina About Me",
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (2).webp"),
    width: 5,
    height: 5,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (3).webp"),
    width: 4,
    height: 6,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (4).webp"),
    width: 4,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (5).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (6).webp"),
    width: 6,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (7).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (8).webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (10).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (11).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (12).webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (13).webp"),
    width: 4,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (14).webp"),
    width: 5,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (16).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (17).webp"),
    width: 4,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (18).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (19).webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (20).webp"),
    width: 4,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (21).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (22).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (23).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (24).webp"),
    width: 5,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (25).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (26).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (27).webp"),
    width: 5,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (28).webp"),
    width: 4,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (29).webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (30).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (31).webp"),
    width: 5,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (32).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (33).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (34).webp"),
    width: 5,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (35).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (36).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (37).webp"),
    width: 5,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (38).webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/portfolioImages/Portfolio (39).webp"),
    width: 9,
    height: 10,
  },
];
