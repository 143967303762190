export const photos = [
  {
    src: require("../../assets/clintsImages/Clint  (1).webp"),
    width: 3,
    height: 3,
    title: "Alina About Me",
  },
  {
    src: require("../../assets/clintsImages/Clint  (2).webp"),
    width: 1,
    height: 2,
  },
  {
    src: require("../../assets/clintsImages/Clint  (3).webp"),

    width: 3,
    height: 4,
  },
  {
    src: require("../../assets/clintsImages/Clint  (4).webp"),

    width: 2,
    height: 4,
  },
  {
    src: require("../../assets/clintsImages/Clint  (5).webp"),

    width: 2,
    height: 4,
  },
  {
    src: require("../../assets/clintsImages/Clint  (6).webp"),

    width: 3,
    height: 3,
  },
  {
    src: require("../../assets/clintsImages/Clint  (7).webp"),

    width: 2,
    height: 4,
  },
  {
    src: require("../../assets/clintsImages/Clint  (8).webp"),

    width: 2,
    height: 3,
  },
  {
    src: require("../../assets/clintsImages/Clint  (9).webp"),

    width: 4,
    height: 3,
  },
  {
    src: require("../../assets/clintsImages/Clint  (10).webp"),

    width: 3,
    height: 4,
  },
  {
    src: require("../../assets/clintsImages/Clint  (11).webp"),

    width: 2,
    height: 3,
  },
  {
    src: require("../../assets/clintsImages/Clint  (12).webp"),

    width: 3,
    height: 2,
  },
  {
    src: require("../../assets/clintsImages/Clint  (13).webp"),

    width: 2,
    height: 3,
  },
  {
    src: require("../../assets/clintsImages/Clint  (14).webp"),

    width: 3,
    height: 3,
  },
  {
    src: require("../../assets/clintsImages/Clint  (15).webp"),

    width: 4,
    height: 3,
  },
];
