export const photos = [
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 1.webp"),
    width: 7,
    height: 10,
    title: "Alina About Me",
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 2.webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 3.webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 4.webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 5.webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 6.webp"),
    width: 7,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 7.webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 8.webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 9.webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 10.webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 11.webp"),
    width: 9,
    height: 10,
  },
  {
    src: require("../../assets/fashionAndCatwalkImages/Fashion&Catwalk 12.webp"),
    width: 9,
    height: 10,
  },
];
