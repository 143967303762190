import React from "react";
import "../../styles/footer.css";

const Footer = () => {
  return (
    <div className="footer-container">
      <div className="footer-info-wrapper">
        <p style={{ fontWeight: "bold", fontSize: "14px" }}>
          Alina Russian MUA | Bridal Hair and Makeup | London
        </p>
        <p>
          All works © Alina Russian MUA 2023. Please do not reproduce without
          the expressed written consent of Alina Russain MUA.
        </p>
      </div>
      <a
        className="mylink"
        target="_blank"
        href="https://www.linkedin.com/in/shoaib-sumbal/"
      ></a>
    </div>
  );
};

export default Footer;
